<!-- 账户流水 -->
<template>
  <div class="app-CashFlow">
    <div class="CashFlow-wrapper">
      <el-row>
        <el-col :span="24">
          <div class="grid-content bg-purple-dark" style="text-align: left">
            <h1
                style="
                  font-size: 30px;
                  font-weight: 500;
                  margin: 0px 0px 10px 0px;
                "
            >
              {{ this.$t('cash.flow_es_ES') }}
            </h1>
            <!-- 表单 -->
            <el-form :model="listQuery" size="small" label-position="left" style="width: 100%">
              <!-- 账户类型 -->
              <el-form-item :label="$t('fi.lable.account.type_es_ES')" style="width: 100%">
                <el-select
                    size="small"
                    v-model="listQuery.acType"
                    :placeholder="$t('list.please.select')"
                >
                  <el-option value=""></el-option>
                  <el-option
                      v-for="role in acTypeList"
                      :key="role.code"
                      :label="$t(role.title)"
                      :value="role.code"
                  />
                </el-select>
              </el-form-item>
              <!-- 时间筛选 -->
              <el-form-item :label="$t('Common.createTime')" style="width: 100%">
                <el-date-picker
                    v-model.trim="listQuery.createdTimeStart"
                    type="date"
                    style="width: 150px;display: inline-block"
                    value-format="yyyy-MM-dd"
                    placeholder=""
                />
                <el-date-picker
                    v-model.trim="listQuery.createdTimeEnd"
                    type="date"
                    style="width: 150px;display: inline-block"
                    value-format="yyyy-MM-dd"
                    placeholder=""
                />
              </el-form-item>
              <el-button size="mini" :loading="btnloading" type="warning" @click="getList" plain>{{$t('btn.implement')}}</el-button>

            </el-form>
          </div>
          <div>
            <!-- 顶部滚动条 -->
            <div ref="topScroll" class="top-scroll">
              <div class="top-scroll-content" :style="{ width: topScrollWidth }"></div>
            </div>
            <!-- 表格 -->
            <el-table
              ref="tableRef"
              :key="tableKey"
              v-loading="listLoading"
              :data="list"
              border
              fit
              highlight-current-row
              style="width: 100%"
            >
              <!--     单号 -->
              <el-table-column :label="$t('order.no')" min-width="150px" prop="orderNo" align="center" />
              <!-- 账户类型 -->
              <el-table-column :label="$t('title.accout.type')" align="center">
                <template v-slot="{ row }">
                  {{ $lt('fiacinput.actype.cn', row.acType) }}
                </template>
              </el-table-column>
              <!-- 用户编号 -->
              <el-table-column
                  min-width="110px"
                :label="$t('fiPayAdvice.userCode')"
                prop="userCode"
                align="center"
              />
              <!-- 姓名 -->
              <el-table-column
                :label="$t('person.name')"
                prop="userName"
                align="center"
              />
              <!-- 余额(操作前余额) -->
              <el-table-column
                :label="$t('Balance.before.operation')" prop="forwardValidBalance" align="center"/>
              <!--      存入-->
              <el-table-column :label="$t('fiAwardItem.dealTypeA')" prop="inValidMoney" align="center" />
              <!--      取出-->
              <el-table-column :label="$t('fiAwardItem.dealTypeD')" prop="outValidMoney" align="center" />
              <!-- 可用余额  操作后余额-->
              <el-table-column :label="$t('Balance.after.operation')" prop="validBalance" align="center"/>
              <!--      交易类别-->
              <el-table-column :label="$t('fiBankbookJournal.dealType')" prop="orderType" align="center" >
                <template v-slot="{row}">
                  {{$lt(initDict.orderType,row.orderType)}}
                </template>
              </el-table-column>
              <!--     日期 -->
              <el-table-column :label="$t('title.date')" prop="dealDate" align="center" />
              <!--     备注 -->
              <el-table-column :label="$t('Common.remark')" min-width="150px" prop="remark" align="center" />
              <!--    贡献人 -->
              <el-table-column :label="$t('bd.Contributor')" min-width="150px" prop="jianjinno" align="center" />
            </el-table>
            <pagination
              v-show="total > 0"
              :total="total"
              :page.sync="listQuery.page"
              :limit.sync="listQuery.limit"
              @pagination="getList"
            />
<!--            <el-button-->
<!--              :loading="btnloading"-->
<!--              @click="Continue()"-->
<!--              style="float: right"-->
<!--              >{{ this.$t('btn.continue') }}</el-button-->
<!--            >-->
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { acBalanceItemList } from '@/api/userInfo/acBalance.js'
import {mapGetters} from "vuex";
export default {
  name: 'CashFlow',
  components: { Pagination },
  data() {
    return {
      topScrollWidth: 0,
      tableDom: null,
      labelPosition: 'left',
      listLoading: false,
      btnloading: false,
      listQuery: {
        page: 1,
        limit: 10,
        acType: 'B$'
      },
      tableKey: 0,
      list: null,
      total: 0,
      initDict: {
        acType: 'fiacinput.actype.cn',
        status: 'account_status',
        orderType: 'ac_change_order_type',
      },
    }
  },
  computed: {
    ...mapGetters([
      'language'
    ]),
    acTypeList() {
      return this.$ll(this.initDict.acType).filter(ele => ele.code === 'B$' || ele.code === 'FB' || ele.code === 'H0' )
    },
  },
  watch: {
    'language'(){
      this.getList()
    },
    topScrollWidth: {
      // 两个滚动条同时滚动
      handler() {
        // 监听滚动条
        this.$nextTick(() => {
          this.tableDom = this.$refs.tableRef.bodyWrapper
          this.tableDom.addEventListener('scroll', () => {
            // 表格顶部滚动条与底部同步
            let scrollLeft = this.tableDom.scrollLeft
            this.$refs.topScroll.scrollTo(scrollLeft, 0);
          })
          let topScroll = this.$refs.topScroll
          topScroll.addEventListener('scroll', () => {
            // 表格底部滚动条与顶部同步
            let scrollLeft = topScroll.scrollLeft
            this.tableDom.scrollTo(scrollLeft, 0);
          })
        })
      },
      deep: true
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    setScrollWidth() {
      //设置顶部滚顶条宽度值为表格的滚动宽度
      this.$nextTick(() => {
        this.topScrollWidth = this.$refs.tableRef.bodyWrapper.scrollWidth + 'px'
      })
    },
    getList() {
      this.listLoading = true
      acBalanceItemList({ ...this.listQuery })
        .then(res => {
          this.listLoading = false
          this.list = res.data.records
          this.total = +res.data.total
          this.setScrollWidth()
        })
        .catch((err) => {
          console.error(err)
          this.listLoading = false
        })
    },
    Continue() {
      // this.$router.push(`/cash-flow`)
    },
  },
}
</script>

<style scoped lang="scss">
.el-tabs__item.is-active {
  color: #f39f43 !important;
  font-size: 26px;
  font-weight: 600;
}

.el-tabs__item {
  color: #333;
  font-size: 22px;
}
.MsoNormal {
  margin: 10px 0px;
  text-align: left;
}
.el-button + .el-button {
  margin-left: 0;
}
.btn {
  width: 100%;
  text-align: left;
  border-bottom: 0px;
  border-radius: 0;
}
.el-form-item {
  margin-top: 30px;
  width: 80%;
  margin-bottom: 15px;
}
.top-scroll {
  width: 100%;
  overflow-x: auto;
}
.top-scroll-content {
  /* 高度不设置的话滚动条出不来 */
  height: 1px;
}

</style>
