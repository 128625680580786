import request from '@/utils/request'

// 查询用户余额列表
export function acBalanceList(query) {
    return request({
        url: '/acBalance/acBalanceList',
        method: 'get',
        params: query
    })
}

// 现金流量流水明细
export function acBalanceItemList(query) {
    return request({
        url: '/acBalance/acBalanceItemList',
        method: 'get',
        params: query
    })
}
